@media screen and (max-width: 1800px){
    /**
    Page
     */
    .page_contenu{
        width: 80%;
    }
    /*
    Mairie
     */
    .mairie_details {
        width: calc(100% - 400px);
    }
}
@media screen and (max-width: 1750px){
    /*
    Petit - 80%
     */
    .header_contenu, .actualite, .maire, .mairie_details, .mariol_lettre, .marioloise_contenu a{
        -webkit-transform: scale(0.85);
        -ms-transform: scale(0.85);
        transform: scale(0.85)
    }
    .actualite:hover{
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .marioloise_contenu {
        width: 80%;
    }
}

@media screen and (max-width: 1650px){

    /*
    Header section
     */
    .header_contenu_decalage {
        margin: 180px 0 0 100px;
    }
    .header_img_liens {
        margin: 50px 0 0 95px;
    }

}

@media screen and (max-width: 1450px){
    /*
    Page
     */
    .page_contenu,  .marioloise_contenu{
        width: 90%;
    }
}

@media screen and (max-width: 1350px){

    /*
    Actualités
    */
    .actualite_slider {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .actualite {
        margin: 20px 0;
    }
    .voir_plus{
        display: none;
    }

    /*
    Mairie section
     */
    .mairie_section {
        display: block;
    }
    .maire {
        min-height: 100px;
        height: auto;
        width: 90%;
        margin: 0 auto;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 250px 1fr 30px;
        grid-template-columns: 250px 1fr 30px;
        -ms-grid-rows: 1fr 50px 30px 30px;
        grid-template-rows: 1fr 50px 30px 30px;
    }
    .maire a:nth-of-type(1) {
        display: inline;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-row-span: 3;
        grid-row-end: 4;
        -ms-grid-column-span: 0;
        grid-column-end: 1;
        -ms-flex-line-pack: center;
        align-content: center;
    }
    .maire p:nth-of-type(1) {
        display: inline;
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-row-span: 0;
        grid-row-end: 1;
        -ms-grid-column-span: 0;
        grid-column-end: 2;
    }
    .maire #Mot{
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-column-span: 0;
        grid-column-end: 2;
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-row-span: 0;
        grid-row-end: 2;
    }
    .maire p:nth-of-type(2) {
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-column-span: 0;
        grid-column-end: 2;
        -ms-grid-row: 3;
        grid-row-start: 3;
        -ms-grid-row-span: 0;
        grid-row-end: 3;
    }
    .maire p:nth-of-type(3) {
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-column-span: 0;
        grid-column-end: 2;
        -ms-grid-row: 4;
        grid-row-start: 4;
        -ms-grid-row-span: 0;
        grid-row-end: 4;
    }
    .maire > *:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1
    }
    .maire > *:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2
    }
    .maire > *:nth-child(3) {
        -ms-grid-row: 1;
        -ms-grid-column: 3
    }
    .maire > *:nth-child(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 1
    }
    .maire > *:nth-child(5) {
        -ms-grid-row: 2;
        -ms-grid-column: 2
    }
    .maire > *:nth-child(6) {
        -ms-grid-row: 2;
        -ms-grid-column: 3
    }
    .maire > *:nth-child(7) {
        -ms-grid-row: 3;
        -ms-grid-column: 1
    }
    .maire > *:nth-child(8) {
        -ms-grid-row: 3;
        -ms-grid-column: 2
    }
    .maire > *:nth-child(9) {
        -ms-grid-row: 3;
        -ms-grid-column: 3
    }
    .maire > *:nth-child(10) {
        -ms-grid-row: 4;
        -ms-grid-column: 1
    }
    .maire > *:nth-child(11) {
        -ms-grid-row: 4;
        -ms-grid-column: 2
    }
    .maire > *:nth-child(12) {
        -ms-grid-row: 4;
        -ms-grid-column: 3
    }
    .mairie_details {
        width: 100%;
        margin: 0;
    }
    .mairie_details_top {
        width: 80%;
        margin: auto;
    }
    .mairie_details_map {
        width: 100%;
        margin: 50px auto 0 auto;
        height: 400px;
    }


    /*
    Mariolaise
     */
    .marioloise {
        height: auto;
    }
    .marioloise_contenu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 350px;
        margin: 20px auto 0 auto;
    }

}

@media screen and (max-width: 1200px){

    /*
Agenda
 */
    .conteneur{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
    }
    .agenda, .mariol_lettre {
        height: 500px;
        width: 100%;
    }
    .agenda h2,
    .mariol_lettre h2{
        font-size: 40px;
    }
    .agenda a,
    .mariol_lettre a {
        margin: 250px 0 0 0;
    }
    .agenda_list {
        width: 100%;
        height: 505px;
    }

}

@media screen and (max-width: 1000px){
    /*
    Header section
     */
    .header_contenu_decalage {
        margin: 180px auto 0 auto;
        text-align: center;
        width: 100%;
    }
    .header_contenu{
        position: relative;
        text-align: center;
    }
    .decalage_bas{
        opacity: 1;
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        transform: translate(0);
    }
    .header_contenu_decalage h2 {
        font-size: 120px;
    }
    .header_contenu_decalage h1{
        margin: 50px auto 0 auto;
    }
    .header_img_liens {
        width: 100%;
        margin: 50px auto 0 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    /*
    Mairie section
     */
    .maire {
        -ms-grid-rows: 1fr 50px 30px 30px;
        grid-template-rows: 1fr 50px 30px 30px;
    }
    .maire a:nth-of-type(1) {
        min-height: 270px;
    }
    .mairie_details_top {
        width: 100%;
    }


}

@media screen and (max-width: 950px){
    /*
    Header
     */
    .header_contenu_decalage {
        margin: 90px auto 0 auto;
    }
    .header_img_liens {
        top: 500px;
    }
    .header_img {
        max-height: 850px;
    }
}

@media screen and (max-width: 850px){
    /*
    Maire
     */
    .mairie_details{
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
    .mairie_details_top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        margin: 40px 0 0 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .mairie_liens a{
        margin: 0 auto 20px auto;
    }
    .mariol_meteo{
        margin: 20px auto 0 auto;
    }
    .mairie_details_map {
        margin: 70px auto 0 auto;
        height: 300px;
    }
}

@media screen and (max-width: 750px){
    /*
    Maire
     */
    .maire {
        display: block;
        width: 80%;
    }
    .maire a:nth-of-type(1) ,.maire img {
        height: 200px;
        display: block;
        margin: 10px auto 0 auto;
    }
    .maire p:nth-of-type(1) {
        display: block;
        margin: 30px auto auto;
        text-align: justify;
    }
    .maire a:nth-of-type(2) {
        margin: 10px 0 60px 0;
    }

    /*
    Mariolaise
     */
    .marioloise_contenu a {
        height: 60px;
        width: 280px;
        font-size: 16px;
    }
    .marioloise_contenu a span {
        height: 40px;
        width: 40px;
        color: #fff;
        margin: 0 15px 0 20px;
    }
    .actualite_titre {
        font-size: 30px;
    }
}

@media screen and (max-width: 700px){

    /*
    Header
     */
    .header_contenu_decalage h2 {
        font-size: 100px;
    }
    .header_contenu_decalage h1 {
        width: 500px;
        font-size: 18px;
    }

}

@media screen and (max-width: 550px){
    /*
    Header
     */
    .header_contenu_decalage h2 {
        font-size: 80px;
    }
    .header_contenu_decalage h1 {
        width: 300px;
    }
}

@media screen and (max-width: 450px){
    /*
    Header
     */
    .header_img_liens{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .header_img_liens .header_lien_mairie, .header_img_liens .header_lien_contact{
        margin: 10px 0 10px 0;
    }
    .header_contenu_decalage h2 {
        font-size: 60px;
    }

    /*
    Actualité
     */
    .actualite_titre {
        width: 85%;
        margin: 10px auto 0 auto;
        font-size: 30px;
    }
    .actualite {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        width: 90%;
        height: auto;
    }
    .actualite .article_titre {
        font-size: 20px;
    }
    .actualite .article_contenu {
        height: 400px;
        width: 100%;
    }
    #article_conseil{
        height: 300px;
    }
    #article_conseil .article_contenu{
        height: 280px;
    }

    /*
    Page contenu
     */
    .page_contenu, .marioloise_contenu {
        width: 95%;
    }

    /*
    Mairie
     */
    .mairie_liens a {
        width: 100%;
        font-size: 16px;
    }
    .mariol_meteo{
        width: 100%;
        height: auto;
        padding: 0 0 20px 0;
        display: none;
    }
    .mariol_meteo_resp{
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .mariol_meteo_resp img{
        width: 100%;
    }
}