/* Classes permettant l'affichage par le bas au défilement */
.decalage_bas{
	opacity: 0;
	-webkit-transform: translateY(200%);
	-ms-transform: translateY(200%);
	transform: translateY(200%);
	-webkit-transition: opacity 1s ease, -webkit-transform 1s ease-in-out;
	transition: opacity 1s ease, -webkit-transform 1s ease-in-out;
	-o-transition: opacity 1s ease, transform 1s ease-in-out;
	transition: opacity 1s ease, transform 1s ease-in-out;
	transition: opacity 1s ease, transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}
.decalage_bas.recadrage_bas{
	opacity: 1;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

/* Section header */
.header_img{
	min-height: 880px;
	height: 100vh;
	width: 100%;
	background-image: url('../images/pages/accueil/fond.jpg');
	background-size: cover;
	background-position: center center;
}
.header_contenu_decalage{
	position: absolute;
	margin: 180px 0 0 300px;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.header_contenu_decalage h2{
	font-family: "Space Grotesk";
	margin: 0;
	font-size: 150px;
}
.header_contenu_decalage h1{
	font-family: "Space Grotesk";
	margin: 0;
	font-size: 20px;
	width: 700px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.header_img_liens{
	position: absolute;
	top: 650px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 50px 0 0 295px;
}

.header_img_liens a{
	text-decoration: none;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	height: 55px;
	width: 200px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 20px;
	font-family: 'varela round';
	font-weight: bold;
	border-radius: 10px;
}

.header_img_liens .header_lien_mairie{
	background: #53B5F5;
	color: #fff;
	margin: 0 15px 0 0;
}
.header_img_liens .header_lien_mairie:hover{
	background: #fff;
	color: #53B5F5;
}
.header_img_liens .header_lien_contact{
	color: #53B5F5;
	background: #fff;
	margin: 0 0 0 15px;
}
.header_img_liens .header_lien_contact:hover{
	background: #53B5F5;
	color: #fff;
}

.scroll_bouton{
	width: 40px;
	height: 40px;
	margin: auto;
}
.scroll_bouton p {
	cursor: pointer;
	width: 40px;
	height: 40px;
	margin: auto;
	padding-top: 60px;
}
.scroll_bouton p span {
	display: block;
	width: 30px;
	height: 30px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-animation: sdb04 2s infinite;
	animation: sdb04 2s infinite;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.scroll_bouton_gris p span{
	border-left: 2px solid #9d9d9d;
	border-bottom: 2px solid #9d9d9d;
}
@-webkit-keyframes sdb04 {
	0% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		-webkit-transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
}
@keyframes sdb04 {
	0% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
		transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		-webkit-transform: rotate(-45deg) translate(-10px, 10px);
		transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
		transform: rotate(-45deg) translate(0, 0);
	}
}

#header_scroll_bouton{
	position: absolute;
	left: 50%;
}
#header_scroll_bouton p span{
	margin-left: -12px;
}
#info_scroll_bouton{
	height: 70px;
	text-align: center;
}

#info_scroll_bouton p span{
	margin-left: 5px;
}

/**
Actualitées
 */
.actualite_conteneur{
	width: 100%;
	background: #F3F3F3;
	padding: 50px 0 60px 0;
}
.actualite_sous_titre{
	margin: 0;
	text-align: center;
	text-transform: uppercase;
	color: #53B5F5;
	font-family: 'Space Grotesk';
}
.actualite_titre{
	margin: 10px;
	text-align: center;
	font-size: 35px;
	font-family: 'Space Grotesk';
}
.actualite_slider{
	margin: 50px 0 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.actualite{
	position: relative;
	padding: 10px;
	height: 350px;
	width: 400px;
	background: #fff;
	margin: 0 20px;
	-webkit-box-shadow: 5px 7px 16px 1px rgba(0,0,0,0.2);
	box-shadow: 5px 7px 16px 1px rgba(0,0,0,0.2);
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	z-index: 10;
}
.actualite:hover {
	cursor: pointer;
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}
.article_titre{
	margin: 10px 15px;
	font-size: 25px;
	font-family: 'Space Grotesk';
	color: #F29F98;
}
.article_contenu{
	width: 95%;
	height: calc(100% - 90px);
	font-size: 18px;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
}
.article_contenu p{
	font-family: "Space Grotesk";
}

.article_contenu img{
	height: 100%;
}

.article_date{
	height: 40px;
	min-width: 260px;
	width: 260px;
	background: rgba(0, 0, 0, 0.5);
	color: #FFFFFF;
	-ms-flex-line-pack: center;
	align-content: center;
	margin: 40px auto 40px auto;
}
.actualite .contenu img {
	max-width: 100%;
	max-height: 100%;
	height: auto;
	display: block;
}

.voir_plus{
	text-decoration: none;
	display: inline-block;
	width: 120px;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
	height: 40px;
	background: #b053d5;
	color: #fff;
	position: absolute;
	margin: 0;
	-webkit-transform: translateY(100px);
	-ms-transform: translateY(100px);
	transform: translateY(100px);
	-webkit-transition: .3s ease-in-out;
	-o-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 2;
}
.actualite:hover + .voir_plus, .voir_plus:hover{
	-webkit-transform: translateY(205px);
	-ms-transform: translateY(205px);
	transform: translateY(205px);
}
/* Cas pour la première actualité */
.voir_plus:nth-of-type(1){
	-webkit-transform: translate(-462px, 100px);
	-ms-transform: translate(-462px, 100px);
	transform: translate(-462px, 100px);
}
.actualite:nth-of-type(1):hover + .voir_plus:nth-of-type(1), .voir_plus:nth-of-type(1):hover{
	-webkit-transform: translate(-462px, 205px);
	-ms-transform: translate(-462px, 205px);
	transform: translate(-462px, 205px);
}
/* Cas pour la troisième */
.voir_plus:nth-of-type(3){
	-webkit-transform: translate(462px, 100px);
	-ms-transform: translate(462px, 100px);
	transform: translate(462px, 100px);
}
.actualite:nth-of-type(3):hover + .voir_plus:nth-of-type(3), .voir_plus:nth-of-type(3):hover{
	-webkit-transform: translate(462px, 205px);
	-ms-transform: translate(462px, 205px);
	transform: translate(462px, 205px);
}

.horairesModif{
	color: #ff0000;
}


/*
Contenu principal de la page
 */
.page_contenu{
	width: 70%;
	margin: auto;
}

/*
	Le mot du maire
 */
#motMaire1{
	display: block;
}
#motMaire2{
	display: none;
}
.mairie_section{
	margin: 100px 0 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
/* Carte présentant le maire  */
.maire{
	min-height: 550px;
	width: 350px;
	background: #fff;
	-webkit-box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
	box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
	border-radius: 10px;
	text-align: center;
	padding: 20px;
}
.maire:hover img{
	border-radius: 5px;
}

.maire img{
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	height: 200px;
	width: 200px;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}

/* Mot du maire */
.maire p{
	letter-spacing: 1.1px;
	font-size: 18px;
	margin: 0;
	text-align: left;
	line-height: 1.8;
}
.maire p:nth-of-type(1){
	margin: 20px 0 0 0;
	color: #666666;
	text-align: justify;
}

/*  Lien : Lire la suite  */
.maire #Mot{
	margin: 10px 0 90px 0;
	display: block;
	font-size: 18px;
	font-weight: bold;
	color: #da6262;
	width: 95px;
	cursor: pointer;
	text-align: center;
	border-bottom: 2px solid transparent;

}

.maire p:nth-of-type(2){
	text-align: left;
	display: block;
	font-weight: bold;
	color: #da6262;
	font-size: 16px;
}

.mairie_details{
	margin: 0 0 0 100px;
	width: calc(100% - 550px);
	text-align: center;
}

.mairie_details_top{
	height: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.mairie_liens a {
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: center;
	align-content: center;
	height: 80px;
	width: 350px;
	background: #fff;
	font-size: 18px;
	border-radius: 10px;
	font-family: 'Varela Round';
	-webkit-transition: all .7s linear;
	-o-transition: all .7s linear;
	transition: all .7s linear;
	margin: 0 0 20px 0;
	-webkit-box-shadow: 3px 4px 15px 5px rgba(83, 181, 245, 0.33);
	box-shadow: 3px 4px 15px 5px rgba(83, 181, 245, 0.33);
	text-decoration: none;
	color: #000;
}
.mairie_liens a:hover{
	background: #0a0800;
	color: #fff;
}

.mairie_liens a span{
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	background: #53B5F5;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
	margin: 0 20px 0 20px;
}

.mariol_meteo{
	border-radius: 10px;
	background: #fff;
	-webkit-box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
	box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
	height: 285px;
	width: 350px;
	text-align: center;
}
.mariol_meteo_resp{
	display: none;
}

#tameteo{
	border-radius: 10px;
	margin: 10px 0 0 0;
}
#tameteo * {
	font-family: "Space Grotesk";
}

.mairie_details_map{
	width: 100%;
	height: 50%;
	margin: auto 0 0 0;
	-webkit-box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
	box-shadow: 3px 4px 15px 5px rgba(83,181,245,0.33);
}
.mairie_details_map iframe{
	width: 100%;
	height: 100%;
}


.conteneur{
	height: 500px;
	width: 100%;
	margin: 100px 0 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.agenda {
	height: 505px;
	width: 400px;
	background-image: url("../images/pages/accueil/agenda.jpg");
	background-size: cover;
	text-align: center;
	-webkit-box-shadow: 5px 7px 16px 1px rgba(0, 0, 0, 0.2);
	box-shadow: 5px 7px 16px 1px rgba(0, 0, 0, 0.2);
}
.agenda .slick-prev, .agenda .slick-next {
	position: absolute;
	top: 50%;
	-webkit-transform: scale(2.5) translateX(0);
	-ms-transform: scale(2.5) translateX(0);
	transform: scale(2.5) translateX(0);
	z-index: 9999;
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.agenda.slick-prev {
	left: 65px;
}
.agenda .slick-prev:before, .agenda .slick-next:before {
	color: #000;
}

.agenda h2{
	color: #ffffff;
	text-shadow: 0px 0px 20px #000000;
	font-size: 50px;
}

#article_conseil_contenu{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.agenda a {
	margin: 69% 0 0 0;
	height: 50px;
	width: 150px;
	color: #fff;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
	text-decoration: none;
	border-radius: 20px;
	border: 2px solid #fff;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.agenda a:hover {
	background: #000;
	border: 2px solid black;
}


.agenda .slick-slide{
	height: 505px;
	width: 100%;
}
.agenda .slick-slide img{
	height: 505px;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}


.aucuneActus{
	font-family: "Space Grotesk";
	font-size: 20px;
}

.agenda_list{
	width: 420px;
	height: 100%;
}

.agenda_list a{
	display: block;
	text-decoration: none;
	width: 100%;
	height: 60px;
	background: #F3F3F3;
	padding: 10px 0;
	margin: 0 0 5px 0;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	color: #000;
}
.agenda_list a:hover{
	background: #000;
	color: #fff;
}

.agenda_list a:hover .agenda_date{
	background: #53B5F5;
}

.agenda_date{
	margin: 0 0 0 20px;
	display: block;
	width: 225px;
	text-align: center;
	border-radius: 15px;
	color: #fff;
	height: 25px;
	-ms-flex-line-pack: center;
	align-content: center;
	background: rgba(0, 0, 0, 0.5);
	-webkit-transition: all .7s ease-in-out;
	-o-transition: all .7s ease-in-out;
	transition: all .7s ease-in-out;;
}
.agenda_contenu{
	margin: 10px 0 0 20px;
}
.mariol_lettre{
	width: 400px;
	height: 505px;
}
.lettre_titre{
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 10%;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.lettre_titre h4{
	display: inline-block;
	font-size: 22px;
	text-align: center;
	color: #FFFFFF;
	font-family: Lobster;
	margin: 0;
}

.lettre_contenu{
	height: 90%;
	width: 100%;
	background: #cccccc;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.lettre_contenu .slick-slide{
	height: 100%;
	width: 100%;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.lettre_contenu .slick-prev:before, .lettre_contenu .slick-next:before
{
	color: #000;
}

.lettre_contenu .slick-slide img {
	display: inline-block;
}

.lettre_contenu .slick-prev{
	-webkit-transform: scale(3) translateX(11px);
	-ms-transform: scale(3) translateX(11px);
	transform: scale(3) translateX(11px);
}

.lettre_contenu .slick-track {
	height: 90%;
}

.lettre_contenu .slick-list{
	height: 90%;
}


.actualite_bloc{
	cursor: pointer;
	position: relative;
	display: block;
	padding: 10px;
	height: 350px;
	width: 400px;
	background: #FFFFFF;
	margin: 0 20px;
	-webkit-box-shadow: 5px 7px 16px 1px rgba(0,0,0,0.2);
	box-shadow: 5px 7px 16px 1px rgba(0,0,0,0.2);
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	z-index: 10;
	overflow: hidden;
	overflow-wrap: break-word;
}

.actualite_bloc *{
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.marioloise{
	height: 300px;
	width: 100%;
	background: #F3F3F3;
	margin: 100px 0 0 0;
	padding: 30px 0;
}
.marioloise_contenu {
	width: 70%;
	margin: auto;
	height: 200px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.marioloise_contenu a {
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: center;
	align-content: center;
	height: 80px;
	width: 350px;
	background: #fff;
	font-size: 18px;
	border-radius: 10px;
	font-family: 'Varela Round';
	text-decoration: none;
	color: #000;
	-webkit-transition: all .7s linear;
	-o-transition: all .7s linear;
	transition: all .7s linear;
	margin: 0 0 20px 0;
	-webkit-box-shadow: 3px 4px 15px 5px rgba(83, 181, 245, 0.33);
	box-shadow: 3px 4px 15px 5px rgba(83, 181, 245, 0.33);
}
.marioloise_contenu a:hover {
	background: #0a0800;
	color: #fff;
}

.marioloise_contenu a span {
	display: inline-block;
	height: 50px;
	width: 50px;
	background: #53B5F5;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	-ms-flex-line-pack: center;
	align-content: center;
	margin: 0 20px 0 20px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}